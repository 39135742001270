// src/utils/passwordValidation.js
export const validatePassword = (password) => {
  const minLength = 8;
  const errors = [];
  
  if (password.length < minLength) {
    errors.push(`Password must be at least ${minLength} characters long`);
  }
  
  return {
    isValid: errors.length === 0,
    errors
  };
};