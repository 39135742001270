import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { validatePassword } from '../utils/passwordValidation';
import { RESET_PASSWORD_MUTATION } from '../graphql/mutations/resetPassword';
import '../styles/loginPage.css';

const PasswordSetupPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [username, setUsername] = useState(location.state?.email || '');
  const [temporaryPassword, setTemporaryPassword] = useState(location.state?.temporaryPassword || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);

  const verificationCodeFromState = location.state?.verificationCode || '';
  
  useEffect(() => {
    if (!location.state || !location.state.email || !location.state.temporaryPassword) {
      setError('Required information is missing. Please start the password setup process from the login page.');
    }
  }, [location.state]);

  const [resetPassword, { loading: resettingPassword }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      history.push('/login');
    },
    onError: (error) => {
      console.error('Reset Password Error:', error);
      setError('Failed to reset password. Please try again.');
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    // Validate password length
    const { isValid, errors } = validatePassword(newPassword);
    if (!isValid) {
      setError(errors[0]);
      return;
    }

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Prevent using the temporary password as the new password
    if (newPassword === temporaryPassword) {
      setError('New password must be different from the temporary password');
      return;
    }

    resetPassword({
      variables: {
        username,
        verification_code: verificationCodeFromState,
        new_password: newPassword,
      },
    });
  };

  return (
    <div className="login-page">
      <Helmet>
        <title>Umps Device List - Password Setup</title>
      </Helmet>
      <div className="login-container">
        <h1>Set Up New Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              readOnly
            />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
            {newPassword.length > 0 && newPassword.length < 8 && (
              <p className="error-text">Password must be at least 8 characters long</p>
            )}
          </div>
          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </div>
          <button type="submit" disabled={resettingPassword}>
            {resettingPassword ? 'Setting Password...' : 'Set New Password'}
          </button>
          {error && <p className="error-text">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default PasswordSetupPage;