// src/utils/auth.js
import client from '../graphql/client';

export const logout = (history) => {
  // Clear any stored tokens
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  
  // Clear Apollo Client cache
  client.clearStore();
  
  // Redirect to login page
  history.push('/login');
};

// A temporary check for authentication until we have proper server-side session management
export const isAuthenticated = () => {
  return !!localStorage.getItem('access_token');
};