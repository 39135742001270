// src/pages/DeviceListPage.js
import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logout } from '../utils/auth';
import { useTable, useSortBy, useFilters } from 'react-table';
import '../styles/deviceListPage.css';
import { LIST_SYSTEMS } from '../graphql/queries/listSystems';
import { formatDistanceToNow } from 'date-fns';

// Constants
const SYSTEMS_PER_PAGE = 500;
const VALID_RSRP_MIN = -150;
const VALID_RSRP_MAX = 0;
const VALID_RSRQ_MIN = -20;
const VALID_RSRQ_MAX = 0;

const FilterInput = ({ column: { filterValue, setFilter } }) => (
  <input
    value={filterValue || ''}
    onChange={e => setFilter(e.target.value || undefined)}
    placeholder="Filter..."
    onClick={e => e.stopPropagation()}
  />
);

FilterInput.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    setFilter: PropTypes.func.isRequired,
  }).isRequired,
};

const DeviceListPage = () => {
  const { location, history } = useHistory();
  const [systems, setSystems] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [fetchSystems] = useLazyQuery(LIST_SYSTEMS, {
    fetchPolicy: 'network-only',
  });
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  const getFormattedTime = useMemo(() => {
    const formatTime = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ` +
             `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    };
    return formatTime;
  }, []);

  useEffect(() => {
    const fetchAllSystems = async () => {
      let allSystems = [];
      let nextToken = null;

      try {
        do {
          const result = await fetchSystems({ 
            variables: { 
              limit: SYSTEMS_PER_PAGE, 
              nextToken 
            } 
          });

          if (result.errors) {
            console.error('GraphQL Errors:', result.errors);
          }

          if (result.data?.listSystems) {
            const validSystems = result.data.listSystems.items.filter((system, index) => {
              if (result.errors) {
                const errorPaths = result.errors.map(err => err.path.join('.'));
                return !errorPaths.includes(`listSystems.items.${index}`);
              }
              const systemState = system.basic_info?.system_status?.system_state;
              return systemState && systemState.trim() !== '';
            });

            allSystems = [...allSystems, ...validSystems];
            nextToken = result.data.listSystems.nextToken;

            const fetchTime = new Date();
            setLastFetchTime(fetchTime);
            const formattedTime = getFormattedTime(fetchTime);
            setLoadingMessage(`${allSystems.length} systems loaded - last updated ${formattedTime}`);
          } else {
            nextToken = null;
          }
        } while (nextToken);

        setSystems(allSystems);
      } catch (e) {
        console.error('Fetching systems failed:', e);
        if (e.networkError?.statusCode === 401) {
          logout();
        }
      }
    };

    fetchAllSystems();
  }, [fetchSystems, getFormattedTime]);

  const columns = useMemo(() => [
    {
      Header: 'System ID',
      accessor: 'system_id',
      Filter: FilterInput,
      Cell: ({ row }) => <span>{row.original.system_id}</span>,
    },
    {
      Header: 'PERS ID',
      accessor: 'configuration.pers_id',
      Filter: FilterInput,
      Cell: ({ value }) => (
        <div style={{ minWidth: '80px', maxWidth: '80px', whiteSpace: 'nowrap' }}>
          {value}
        </div>
      ),
    },
    {
      Header: 'Provider',
      accessor: 'advanced_info.organisation_setting.pers_provider',
      Filter: FilterInput,
      Cell: ({ value }) => (
        <div style={{ minWidth: '100px', maxWidth: '100px', whiteSpace: 'nowrap' }}>
          {value}
        </div>
      ),
    },
    {
      Header: 'Region',
      accessor: 'advanced_info.organisation_setting.pers_region',
      Filter: FilterInput,
      Cell: ({ value }) => (
        <div style={{ minWidth: '200px', maxWidth: '200px', whiteSpace: 'nowrap' }}>
          {value}
        </div>
      ),
    },
    {
      Header: 'State',
      accessor: row => {
        const value = row.basic_info?.connection_state;
        return value?.toLowerCase() === 'connected' ? 'Online' : 'Offline';
      },
      Filter: FilterInput,
      Cell: ({ value }) => (
        <div style={{ minWidth: '80px', maxWidth: '80px', whiteSpace: 'nowrap' }}>
          {value}
        </div>
      ),
    },
    {
      Header: 'Last State Change',
      accessor: row => {
        const items = row.advanced_info?.connectivity_events?.items;
        return Array.isArray(items) && items[0]?.timestamp_utc
          ? items[0].timestamp_utc
          : '';
      },
      Cell: ({ value }) => {
        if (!value) return 'No Data';
        
        const date = new Date(value);
        if (isNaN(date)) return 'Invalid Date';
        
        const formattedDate = getFormattedTime(date);
        const relativeTime = formatDistanceToNow(date, { addSuffix: true }); 

        return (
          <span title={relativeTime} style={{ cursor: 'pointer' }}> 
            {formattedDate}
          </span>
        );
      },
      Filter: FilterInput,
    },
    {
      Header: 'Uptime (7d)',
      accessor: 'basic_info.system_uptime',
      Cell: ({ value }) => {
        if (value === null || value === undefined) return 'No Data';
        const uptimePercentage = parseFloat(value);
        return isNaN(uptimePercentage) ? 'Invalid Uptime' : `${uptimePercentage.toFixed(2)}%`;
      },
      Filter: FilterInput,
    },
    {
      Header: 'Average Signal (7d)',
      accessor: row => {
        const events = row.advanced_info?.mobile_network_events?.items;
        if (!Array.isArray(events) || events.length === 0) return null;

        let totalMinSignalPercentage = 0;
        let totalSignalPower = 0;
        let totalSignalQuality = 0;
        let countMinSignalPercentage = 0;
        let countSignalPower = 0;
        let countSignalQuality = 0;
        let numDataPoints = 0;

        events.forEach(event => {
          const { signal_power, signal_quality, min_signal_percentage } = event;
          const isValidSignalPower = typeof signal_power === 'number' && 
            signal_power >= VALID_RSRP_MIN && signal_power <= VALID_RSRP_MAX;
          const isValidSignalQuality = typeof signal_quality === 'number' && 
            signal_quality >= VALID_RSRQ_MIN && signal_quality <= VALID_RSRQ_MAX;

          if (isValidSignalPower) {
            totalSignalPower += signal_power;
            countSignalPower++;
          }

          if (isValidSignalQuality) {
            totalSignalQuality += signal_quality;
            countSignalQuality++;
          }

          if (typeof min_signal_percentage === 'number') {
            totalMinSignalPercentage += min_signal_percentage;
            countMinSignalPercentage++;
          }

          if (isValidSignalPower || isValidSignalQuality || typeof min_signal_percentage === 'number') {
            numDataPoints++;
          }
        });

        if (numDataPoints === 0) return null;

        return {
          percentage: countMinSignalPercentage > 0 ? totalMinSignalPercentage / countMinSignalPercentage : null,
          rsrp: countSignalPower > 0 ? totalSignalPower / countSignalPower : null,
          rsrq: countSignalQuality > 0 ? totalSignalQuality / countSignalQuality : null,
          numDataPoints,
        };
      },
      Cell: ({ value }) => (
        <div style={{ minWidth: '400px', maxWidth: '400px', whiteSpace: 'nowrap' }}>
          {value ? (() => {
            const { percentage, rsrp, rsrq, numDataPoints } = value;
            const percentageStr = percentage !== null ? `${percentage.toFixed(1)}%` : 'N/A';
            const rsrpStr = rsrp !== null ? `${rsrp.toFixed(1)} dBm` : 'N/A';
            const rsrqStr = rsrq !== null ? `${rsrq.toFixed(1)} dB` : 'N/A';
            return `${percentageStr} (RSRP ${rsrpStr}, RSRQ ${rsrqStr}, ${numDataPoints} datapoints)`;
          })() : 'No data'}
        </div>
      ),
      Filter: FilterInput,
      sortType: (rowA, rowB) => {
        const a = rowA.values['Average Signal (7d)'];
        const b = rowB.values['Average Signal (7d)'];
        if (!a && !b) return 0;
        if (!a) return -1;
        if (!b) return 1;
        return (a.percentage ?? -Infinity) - (b.percentage ?? -Infinity);
      },
    },
  ], [getFormattedTime]);

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const value = row.values[id];
          if (!value) return false;
          
          const { percentage, rsrp, rsrq, numDataPoints } = value;
          const valueStr = `${percentage?.toFixed(1) ?? 'N/A'}% (RSRP ${rsrp?.toFixed(1) ?? 'N/A'} dBm, RSRQ ${rsrq?.toFixed(1) ?? 'N/A'} dB, ${numDataPoints} datapoints)`;
          return valueStr.toLowerCase().includes(filterValue.toLowerCase());
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    { 
      columns, 
      data: systems,
      filterTypes,
    },
    useFilters,
    useSortBy
  );

  return (
    <div className="container">
      <Helmet>
        <title>Umps Device List</title>
      </Helmet>
      <div className="loading-message">
        {loadingMessage}
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : ''}
                  </span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td key={cell.column.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

DeviceListPage.propTypes = {
  fetchSystems: PropTypes.func,
};

export default DeviceListPage;